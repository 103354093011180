import { useAxios } from './../axiosUtil'
// import axios from 'axios'

const token = localStorage.getItem('token')
const tokenType = localStorage.getItem('tokenType')

/**
 * 공지사항 저장
 * @param {*} noticeData 저장할 공지사항 데이터
 */
export const saveNotice = async (noticeData) => {
  // 공지사항 추가 url
  const noticeUrl = '/v1/board/lab-notice'

  const headerOpt = {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`
    }
  }

  const savedNoticeData = { data: noticeData, ...headerOpt }

  return await useAxios(noticeUrl, savedNoticeData)
}

/**
 * 공지사항 수정
 * @param {*} boardNo 공지사항 게시글 번호
 * @param {*} noticeData 수정할 공지사항 데이터
 */
export const updateNotice = async (boardNo, noticeData) => {
  // 공지사항 수정 url
  const noticeUrl = `/v1/board/lab-notice/${boardNo}`

  const headerOpt = {
    method: 'PUT',
    headers: {
      Authorization: `${tokenType} ${token}`
    }
  }

  const updatedNoticeData = { data: noticeData, ...headerOpt }

  return await useAxios(noticeUrl, updatedNoticeData)
}

/**
 * 공지사항 조회
 * @param {} boardNo 공지사항 게시글 번호
 */
export const readNotice = async (boardNo) => {
  // 공지사항 조회 url
  const noticeUrl = `/v1/board/lab-notice/${boardNo}`

  return await useAxios(noticeUrl)
}

/**
 * 공지사항 삭제
 * @param {*} boardNo 공지사항 게시글 번호
 */
export const deleteNotice = async (boardNo, noticeData) => {
  // 공지사항 삭제 url
  const noticeUrl = `/v1/board/lab-notice/${boardNo}`

  const headerOpt = {
    method: 'DELETE',
    headers: {
      Authorization: `${tokenType} ${token}`
    }
  }

  const deletedNoticeData = { data: noticeData, ...headerOpt }

  return await useAxios(noticeUrl, deletedNoticeData)
}

//INFO: 공지사항 게시판 리스트
<script>
import { GridComponent, ColumnsDirective, Page, Sort, Toolbar, Search, CommandColumn } from '@syncfusion/ej2-vue-grids'
// import { readNotice } from '@/api/admin/notice'
import { getNotice } from '@/api/platform/community' // 공지사항 조회
import { deleteNotice } from '@/api/admin/notice' // 공지사항 삭제
import AddBtn from '@/components/ui/AddBtn.vue' // 공지사항 등록

import Dropdown from '@/components/ui/Dropdown.vue'

export default {
  name: 'adminBoardNoticeList',
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    AddBtn,
    Dropdown
  },
  data() {
    return {
      currentPage: '', // 현재 페이지 번호
      noticeGrid: '', // 공지사항 그리드
      labNo: '', // 연구실 번호

      pageSettings: { pageSize: 10 }, // 10개씩 잘라서 페이지네이션
      toolbarOptions: ['Search'],
      commands: [{ buttonOption: { content: '삭제', cssClass: 'e-danger' } }], // 삭제 버튼
      formatoptions: { type: 'dateTime', format: 'y/M/d' },
      noticeData: [],
      persistedData: [],
      searchConditions: [
        { value: 'noticeId', text: '번호' },
        { value: 'noticeType', text: '공지유형' },
        { value: 'title', text: '제목' },
        { value: 'name', text: '작성자' }
      ],
      searchBy: ''
    }
  },
  provide: {
    grid: [Page, Sort, Toolbar, Search, CommandColumn]
  },
  mounted() {
    this.setSearchOptions()
    // 공지사항 그리드
    this.noticeGrid = this.$refs.grid.ej2Instances

    const noticeTypeModel = {
      0: '공지사항',
      1: '대학별 뉴스',
      2: '연구실 뉴스'
    }

    // 파라미터 프로젝트 그리드 페이지 번호
    const page = Number(this.$route.params.page)

    // 공지사항 전체 데이터 가져오기
    const loadNoticeData = async () => {
      const data = await getNotice()
      this.noticeData = data.items.map((item) => {
        return { ...item, noticeType: noticeTypeModel[item.noticeType] }
      })

      this.persistedData = this.noticeData
    }
    loadNoticeData()

    // 페이지 번호가 있을 때(프로젝트 보기 -> 목록으로 돌아온 경우) 받아온 페이지 번호로 SET
    if (page) {
      this.noticeGrid.pagerModule.pageSettings.currentPage = page
    } else {
      this.noticeGrid.pagerModule.pageSettings.currentPage = 1
    }
  },
  methods: {
    // ********** 한 행 클릭 시 해당 게시글 보기로 이동 **********
    rowSelected(e) {
      // 그리드 현재 페이지
      this.currentPage = this.noticeGrid.pagerModule.pageSettings.currentPage

      // 한 행 클릭한 타켓이 버튼일 경우 리턴
      if (e.target.matches('button')) return

      this.$router.push({
        path: `/admin/board/notice/noticeCreate/${e.data.noticeId}/${this.currentPage}`
      })
    },

    // ********** 공지사항 삭제 버튼 **********
    commandClick(e) {
      const target = e.target
      // 타겟이 삭제 버튼일 경우 삭제 동작 함수 호출
      if (target.matches('button.e-danger')) return this.delNotice(e)
    },

    // ********** 삭제 동작 **********
    async delNotice(e) {
      if (!confirm('선택한 공지사항을 정말로 삭제하시겠습니까?')) return

      const boardNo = e.rowData.noticeId
      const result = await deleteNotice(boardNo, e.rowData)
      if (result.code) return alert(result.message)
      alert('삭제되었습니다.')
      this.noticeData = this.noticeData.filter((item) => item.noticeId !== boardNo)
    },

    // ********** 공지사항 작성 버튼 **********
    goBoardCreate() {
      this.$router.push({
        path: '/admin/board/notice/noticeCreate'
      })
    },
    actionComplete(e) {
      if (e.requestType === 'searching') return this.searchInSyncfusionGrid(e)
    },
    searchInSyncfusionGrid(e) {
      this.noticeData = this.persistedData.filter((item) => item[this.searchBy]?.toString().includes(e.searchString) ?? true)
    },
    selectDropdownHandler(opt) {
      this.searchBy = opt.value
    },
    setSearchOptions() {
      const searchWrapper = this.$refs.grid.$el.querySelector('.e-toolbar-right')
      const dropdown = this.$refs.selectOption.$el
      searchWrapper.prepend(dropdown)

      searchWrapper.style.display = 'flex'
      searchWrapper.style.gap = '1em'
      dropdown.querySelector('button').style.border = 'none'
    }
  }
}
</script>

<template>
  <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h4>공지사항 게시판</h4>
  </nav>
  <div id="content-wrapper" class="d-flex flex-column">
    <AddBtn @click="goBoardCreate()"></AddBtn>

    <Dropdown
      class="select-wrapper"
      :options="searchConditions"
      :disabled="false"
      name="zipcode"
      ref="selectOption"
      @selected="selectDropdownHandler"
    ></Dropdown>

    <div class="">
      <div class="">
        <ejs-grid
          ref="grid"
          :dataSource="noticeData"
          :allowPaging="true"
          :pageSettings="pageSettings"
          :allowSorting="true"
          :toolbar="toolbarOptions"
          :rowSelected="rowSelected"
          :commandClick="commandClick"
          :actionComplete="actionComplete"
        >
          <e-columns>
            <e-column field="noticeId" headerText="번호" width="120" textAlign="center"></e-column>
            <e-column field="noticeType" headerText="공지유형" width="120" textAlign="center"></e-column>
            <e-column field="title" headerText="제목" textAlign="left"></e-column>
            <e-column field="name" headerText="작성자" width="150" textAlign="center"></e-column>
            <e-column field="createdAt" headerText="작성일" width="130" :format="formatoptions" type="date" textAlign="center"></e-column>
            <e-column field="manage" headerText="관리" width="100" textAlign="center" :commands="commands" />
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
